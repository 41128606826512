


















import ProfileCards from '@/components/ProfileCards.vue';
import Loader from '@/components/UI/Loader.vue';
import { ProfileFilter } from 'client-website-ts-library/filters';
import { View } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import { Office, Profile } from 'client-website-ts-library/types';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    ProfileCards,
    Loader,
  },
})
export default class About extends Mixins(View) {
private profiles: Profile[] = [];

  private office: Office | null = null;

  mounted() {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
    });

    const filter = new ProfileFilter();

    API.Profiles.Search(filter).then((profiles) => {
      this.profiles = profiles;
    });
  }
}
